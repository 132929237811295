import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Container from '../components/container';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Container>
      <h1>Página no encontrada</h1>
      <p>
        La página que intentabas cargar no existe. Por favor, vuelva e inténtelo
        de nuevo.
      </p>
    </Container>
  </Layout>
);

export default NotFoundPage;
